import React from "react";
import { ContactForm } from "../components/molecules/ContactForm/ContactForm";
import WhereToFind from "../components/molecules/WhereToFind/WhereToFind";
import * as s from "./contact.module.scss";
import { PageTitle, SEO } from "../modules/seo";

const Contact = () => {
  return (
    <div className={s.container}>
      <SEO title={PageTitle.Contact} />
      <ContactForm />
      <WhereToFind />
    </div>
  );
};

export default Contact;
