import React from "react";
import foxtrot from "../../../images/Foxtrot.png";
import zitomer from "../../../images/Zitomer.png";
import erewhon from "../../../images/Erewhon.png";
import showfields from "../../../images/Showfields.png";
import * as s from "./WhereToFind.module.scss";
import { Link } from "gatsby";

const WhereToFind = () => {
  return (
    <div className={s.mainContainer}>
      <div className={s.titleContainer}>
        <h2>Where to find </h2>
      </div>
      <div className={s.container}>
        <div className={s.imageContainer}>
          <Link to="https://foxtrotco.com/">
            <img src={foxtrot} alt="Foxtrort Logo" />
          </Link>
        </div>
        <div className={s.imageContainer}>
          <Link to="https://www.zitomer.com/">
            <img src={zitomer} alt="Zitomer Logo" />
          </Link>
        </div>
        <div className={s.imageContainer}>
          <Link to="https://www.erewhonmarket.com/">
            <img src={erewhon} alt="Erewhon Logo" />
          </Link>
        </div>
        <div className={s.imageContainer}>
          <Link to="https://showfields.com/">
            <img src={showfields} alt="Showfields Logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhereToFind;
