/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import classNames from "classnames";
import * as s from "./ContactForm.module.scss";
import { useState } from "react";
// import img1 from "../../../images/GLJIVA.webp";
import ig from "../../../images/Social Icons.svg";
import fb from "../../../images/fb.svg";
import tik from "../../../images/tiktok.svg";
import twitter from "../../../images/twitter.svg";
import randomMushroom from "../../../images/RandomMushroom.png";
import { toast } from "react-toastify";

const cn = classNames.bind(s);

const ContactForm = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [error, setError] = useState("");

  const handleClick = async () => {
    if (
      contactData.name === "" ||
      contactData.email === "" ||
      contactData.message === ""
    )
      setError("All fields are required!");
    else {
      const resp = await fetch("https://usebasin.com/f/a070d5ddbb85", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(contactData),
      });
      if (resp.status === 200) {
        toast("Thank you for contacting us!");
        setContactData({ name: "", email: "", message: "" });
      }
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const newObject = {
      ...contactData,
      [e.target.name]: e.target.value,
    };
    setContactData(newObject);
  };

  return (
    <div className={cn(s.container)}>
      {/* <img src={img1} alt="muhsroom" className={s.mushroom} /> */}
      <div className={s.wrapper}>
        <div className={s.linkWrapper}>
          <div className={s.textWrapper}>
            <p className={s.title}>
              Let’s <span>Connect</span>
            </p>
            <p className={s.description}>
              We love a good chat. Email us any questions and comments and we’ll
              get back to you ASAP!
            </p>
          </div>

          <div className={s.icondiv}>
            <img
              src={ig}
              alt=""
              className={s.img}
              onClick={() =>
                window.open("https://www.instagram.com/trytroop/?hl=en")
              }
            ></img>
            <img
              src={twitter}
              alt=""
              className={s.img}
              onClick={() => window.open("https://twitter.com/TryTroop")}
            ></img>
            <img
              src={fb}
              alt=""
              className={s.img}
              onClick={() => window.open("https://www.facebook.com/trytroop/")}
            ></img>
            <img
              src={tik}
              alt=""
              className={s.img}
              onClick={() => window.open("https://www.tiktok.com/@trytroop?")}
            ></img>
          </div>
          <div className={s.mushroomImageContainer}>
            <img src={randomMushroom} alt="Greeen Mushroom" />
          </div>
        </div>

        <div className={s.inputWrapper}>
          <label htmlFor="first-name">
            First and last name<span>*</span>
          </label>
          <input
            placeholder="Please type in your name"
            onChange={(e) => handleChange(e)}
            name="name"
            value={contactData.name}
            className={cn(s.field)}
          />
          <label htmlFor="email">
            Email address<span>*</span>
          </label>
          <input
            placeholder="Please type in your email address"
            onChange={(e) => handleChange(e)}
            name="email"
            value={contactData.email}
            className={cn(s.field)}
          />
          <label htmlFor="comments">
            Talk to us<span>*</span>
          </label>
          <textarea
            placeholder="Comment"
            onChange={(e) => handleChange(e)}
            name="message"
            value={contactData.message}
            className={s.field}
          />

          <p className={s.errorMsg}>{error}</p>

          <button onClick={() => handleClick()}>Send</button>
        </div>
      </div>
    </div>
  );
};

export { ContactForm };
