// extracted by mini-css-extract-plugin
export var container = "ContactForm-module--container--Kv-sP";
export var description = "ContactForm-module--description--UTVOW";
export var errorMsg = "ContactForm-module--error-msg--mUcv7";
export var field = "ContactForm-module--field--fXs8N";
export var icondiv = "ContactForm-module--icondiv--URW+j";
export var img = "ContactForm-module--img--x9-UV";
export var inputWrapper = "ContactForm-module--input-wrapper--jZskZ";
export var linkWrapper = "ContactForm-module--link-wrapper--2RK+N";
export var mushroom = "ContactForm-module--mushroom--4MRaN";
export var mushroomImageContainer = "ContactForm-module--mushroomImageContainer--TnkkM";
export var textWrapper = "ContactForm-module--text-wrapper--Aiqkh";
export var title = "ContactForm-module--title--EgZyg";
export var wrapper = "ContactForm-module--wrapper--Tr-pr";